'use client';
import cn from 'classnames';
import { Select } from 'antd';
import { CaretDownOutlined } from '@ant-design/icons';
import withTheme from '@/theme';
import styles from './index.module.scss';

type Props = {
    dataList: {
        name: string;
        key: string;
    }[];
    onClick?: (item: any) => void;
    defaultValue?: string;
    className?: string;
    popupClassName?: string;
    value?: any;
};

const MediaDropdown = (props: Props) => {
    const { dataList, onClick, defaultValue, className, popupClassName, value } = props;

    const items =
        dataList?.map((item: any) => {
            return {
                label: <div className='text-[#0c2d69] px-[10px] text-sm'>{item?.name}</div>,
                value: item?.name,
                key: item?.key,
            };
        }) || [];
    return withTheme(
        <Select
            defaultValue={defaultValue || ''}
            onSelect={(_value, option) => {
                onClick && onClick(option);
            }}
            value={value}
            suffixIcon={<CaretDownOutlined />}
            options={items}
            className={cn('w-fit', className, styles.selectWrap)}
            popupClassName={cn(styles.selectDropWrap, popupClassName)}
        />,
        {
            components: {
                Select: {
                    activeBorderColor: 'transparent',
                    activeOutlineColor: 'transparent',
                    optionSelectedBg: 'transparent',
                },
            },
        },
    );
};

export default MediaDropdown;
