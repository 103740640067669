'use client';
import React, { CSSProperties, useEffect, useRef } from 'react';
import cn from 'classnames';
import { Swiper, SwiperSlide, SwiperProps, SwiperRef } from 'swiper/react';

import {
    Navigation,
    Pagination,
    // EffectCoverflow,
    Autoplay,
    EffectFade,
    // @ts-ignore
} from 'node_modules/swiper/swiper.esm';
// import { Navigation, Pagination, EffectCoverflow, EffectFade, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/effect-coverflow';
import { SwiperModule } from 'swiper/types';

type sliderProp = {
    className?: string;
    slideTo?: number;
    children: any;
    settings?: SwiperProps;
    showPagination?: boolean;
    styleParams?: CSSProperties;
    modules?: SwiperProps['modules'];
};

export const Slider = ({
    children,
    settings,
    className,
    slideTo = 0,
    showPagination = true,
    styleParams,
    modules,
}: sliderProp) => {
    const swiperRef = useRef<SwiperRef>(null);

    const style = {
        '--swiper-pagination-color': '#fecb00',
        '--swiper-pagination-bullet-inactive-color': '#0c2d69',
        '--swiper-pagination-bullet-inactive-opacity': '1',
        '--swiper-pagination-bullet-size': '16px',
        '--swiper-pagination-bullet-horizontal-gap': '6px',
        color: '#fff',
        ...styleParams,
    } as React.CSSProperties;

    // const setModules = showPagination
    //     ? [EffectFade, Autoplay, Navigation, Pagination]
    //     : [Navigation, EffectCoverflow];

    const handleSlideIsNotLoop = (swiperRef: any) => {
        const $target = swiperRef.current;
        const $swiperRef = $target?.swiper;
        const isLoop = $swiperRef?.passedParams.loop;
        const $prevArrowIcon = swiperRef?.current.querySelectorAll('.anticon-arrow-left');
        const $nextArrowIcon = swiperRef?.current.querySelectorAll('.anticon-arrow-right');
        if (isLoop) return;

        const classes = ['opacity-50', 'pointer-events-none', 'touch-action-none'];

        if ($swiperRef?.isBeginning) {
            $prevArrowIcon[0]?.classList.add(...classes);
        } else {
            $prevArrowIcon[0]?.classList.remove(...classes);
        }

        if ($swiperRef?.isEnd) {
            $nextArrowIcon[0]?.classList.add(...classes);
        } else {
            $nextArrowIcon[0]?.classList.remove(...classes);
        }
    };

    const setModules = showPagination
        ? ([
              Navigation,
              Pagination,
              //   EffectCoverflow,
              EffectFade,
              settings?.autoplay && Autoplay,
          ].filter(Boolean) as SwiperModule[])
        : [Navigation, EffectFade];

    const sliderOptions = {
        modules: modules || setModules,
        slidesPerView: 1,
        pagination: {
            clickable: true,
        },
        // loop: true,
        // effect: 'fade',
        ...settings,
    };

    useEffect(() => {
        // Slide to target slide
        if (settings?.loop) {
            swiperRef.current?.swiper.slideToLoop(slideTo);
            return;
        }
        swiperRef.current?.swiper.slideTo(slideTo);
    }, [slideTo]);

    return (
        <div className={cn('slider-component-wrap', className)}>
            <Swiper
                {...sliderOptions}
                ref={swiperRef}
                style={style}
                onAfterInit={() => {
                    handleSlideIsNotLoop(swiperRef);
                }}
                onSlideChange={() => {
                    handleSlideIsNotLoop(swiperRef);
                }}
            >
                {children}
            </Swiper>
        </div>
    );
};

export { SwiperSlide as Slide };
