'use client';
import { Col, Row, Image } from 'antd';
import styles from './index.module.scss';
import cn from 'classnames';
import { withFullPath } from '@/utils/utils';
import { NewsPageItemFragment } from '@/@types/generated/sitecore';
import { useTranslation } from '@/i18n/use-transition.client';
import { format } from 'date-fns';
import { useRouter } from 'next/navigation';
import useIsMobile from '@/hooks/useIsMobile';
import { useEffect, useState } from 'react';

interface Props {
    className?: string;
    lang?: string;
    content: {
        newsList?: { targetItems?: Array<NewsPageItemFragment> };
        template: { name: string };
    };
}

/** NewsBlock */
const HighlightItem = (props: Props) => {
    const router = useRouter();
    // console.log('HighlightItem-"NewsBlock"-----------', props);
    const { isMobile } = useIsMobile();
    const { t } = useTranslation();
    const newsList =
        props.content?.newsList?.targetItems && props.content?.newsList?.targetItems?.length > 0
            ? props.content?.newsList?.targetItems.slice(0, 2)
            : [];

    const [windowWidth, setWindowWidth] = useState<number>(0);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        handleResize();

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    // console.log('newsList-"NewsBlock"-----------', newsList);

    return (
        <div className={cn(styles.HighlightItem, isMobile && 'px-[10px] overflow-x-hidden')}>
            <h1>{t('Highlights')}</h1>
            <Row className='RowContainer'>
                {newsList.map((i, index) => {
                    const date = new Date(i.lastUpdate?.dateValue ?? 0);
                    const yearPath = format(date, 'yyyy');
                    const monthPath = format(date, 'MM');

                    return (
                        <Col
                            span={isMobile ? 24 : 12}
                            className={`picbox img-box cursor-pointer ${index === 0 && !isMobile ? '!flex justify-end' : ''}`}
                            // style={{ maxWidth: '555px' }}
                            key={index}
                            onClick={() => {
                                router.push(`news/${yearPath}/${monthPath}/${i.name}`);
                            }}
                        >
                            <div className='focusImage picbox_con'>
                                <Image
                                    preview={false}
                                    src={withFullPath(i.newsImage?.src ?? '')}
                                    alt=''
                                    width={isMobile ? `${windowWidth - 30}px` : 555}
                                    className='highlight_img'
                                />
                                <div className='focusImageTitle'>
                                    <div className='focusTitle ng-binding'>
                                        {i.newsHighlightText?.value ?? ''}
                                    </div>
                                </div>
                            </div>
                        </Col>
                    );
                })}
            </Row>
        </div>
    );
};

export default HighlightItem;
