'use client';
import { useState, useMemo } from 'react';
import { Flex } from 'antd';
import { AlbumFolderFragment } from '@/@types/generated/sitecore';
import Masonry from 'react-masonry-css';
import ImagePreview, { ImagePreviewGroup } from '@/components/ImagePreview';

type Props = {
    albumItemData: AlbumFolderFragment | undefined;
};

const AlbumPart = (props: Props) => {
    const [currentIndex, setCurrentIndex] = useState<number | undefined>(undefined);
    const { categoriesTitle, albumFolderList = [] } = props.albumItemData || {};

    const imageList = useMemo(
        () =>
            albumFolderList.map((item, index) => ({
                id: index,
                src: item?.overlayImageUrl?.src || item?.imageUrl?.src || '',
                mediaCaption: item?.imageDescription?.value || '',
            })),
        [albumFolderList],
    );

    return (
        <Flex vertical gap={10}>
            <div className='text-[#0c2d69] text-[1.125em] font-semibold mt-[10px]'>
                {categoriesTitle?.value ?? ''}
            </div>
            <Flex gap={10} wrap>
                <ImagePreviewGroup
                    imageList={imageList}
                    currentPreviewIndex={currentIndex}
                    handelVisibleChange={(visible: boolean) => {
                        if (!visible) {
                            setCurrentIndex(undefined);
                        }
                    }}
                >
                    <Masonry
                        breakpointCols={{
                            default: 3,
                            1024: 3,
                            768: 3,
                            550: 2,
                        }}
                        className='flex gap-1'
                        columnClassName='grid-item'
                    >
                        {albumFolderList.map((i, index) => (
                            <ImagePreview
                                wrapClass='cursor-pointer'
                                key={index}
                                canPreview
                                setting={{
                                    src: i.imageUrl?.src ?? '',
                                    alt: '',
                                    onClick: () => {
                                        setCurrentIndex(index);
                                    },
                                }}
                            />
                        ))}
                    </Masonry>
                </ImagePreviewGroup>
            </Flex>
        </Flex>
    );
};

export default AlbumPart;
