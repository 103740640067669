'use client';

import { Slider } from '../Slider';
// import { Navigation, Pagination, EffectFade, Autoplay, Scrollbar, A11y } from 'swiper/modules';
import { Image } from 'antd';
import { SliderFragment } from '@/@types/generated/sitecore';
import { withFullPath } from '@/utils/utils';
import { SwiperSlide } from 'swiper/react';
import { useEffect, useState } from 'react';
import useIsMobile from '@/hooks/useIsMobile';

interface Props {
    content: { template: { name: string }; sliderList: Array<SliderFragment> };
}

const HomeSlide = (props: Props) => {
    // console.log('HomeSlide-----', props);
    const sliderList = props.content.sliderList ?? [];

    const [windowWidth, setWindowWidth] = useState<number>(0);
    const { isMobile } = useIsMobile();

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        handleResize();

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    // console.log('sliderList-----', sliderList);
    return (
        <div className='w-full'>
            <Slider
                settings={{
                    loop: true,
                    slidesPerView: 1,
                    pagination: {
                        clickable: true,
                    },
                    autoplay: {
                        delay: 4000,
                        disableOnInteraction: false,
                    },
                    touchStartPreventDefault: true,
                }}
                className='w-full'
            >
                {sliderList.map(
                    (i, index) =>
                        i.slideEnabled?.boolValue && (
                            <SwiperSlide key={`${index}${i.slideImage?.src}`}>
                                <Image
                                    preview={false}
                                    src={
                                        isMobile
                                            ? withFullPath(i.slideMobileImage?.src)
                                            : withFullPath(i.slideImage?.src)
                                    }
                                    style={{ width: '100%', objectFit: 'contain' }}
                                    width={`${windowWidth}px`}
                                    alt=''
                                />
                            </SwiperSlide>
                        ),
                )}
            </Slider>
        </div>
    );
};

export default HomeSlide;
