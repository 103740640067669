'use client';
import { Modal, ModalProps, ThemeConfig } from 'antd';
import cn from 'classnames';
import withTheme from '@/theme';
import style from './index.module.scss';

type Props = {
    theme?: ThemeConfig;
} & ModalProps;

const Dialog = (props: Props) => {
    const { children, theme = {} } = props;
    const defaultTheme = {
        components: {
            Modal: {
                contentBg: 'transparent',
                boxShadow: '',
            },
        },
    };
    return withTheme(
        <Modal
            footer={null}
            centered
            {...props}
        >
            {children}
        </Modal>,
        { ...defaultTheme, ...theme },
    );
};

export default Dialog;
