'use client';
import { AlbumCategoriesBlockQueryResult, AlbumFolderFragment } from '@/@types/generated/sitecore';
import useIsMobile from '@/hooks/useIsMobile';
import { Row, Col, Flex } from 'antd';
import AlbumPart from './AlbumPart';
import AlbumSideBar from './AlbumSideBar';
import { usePathname, useRouter } from 'next/navigation';
import { useMemo } from 'react';
import MediaDropdown from '@/components/MediaDropdown';
import { useTranslation } from '@/i18n/use-transition.client';

type Props = {
    page: string;
    content: AlbumCategoriesBlockQueryResult['content'] & {
        albumList?: { targetItems?: Array<AlbumFolderFragment> };
        template: { name: string };
    };
};

const AlbumPageLayout = (props: Props) => {
    const { page, content } = props;
    const { isMobile } = useIsMobile();
    const pathname = usePathname();
    const router = useRouter();
    const { t } = useTranslation();

    const AlbumSideBarData = content?.albumList?.targetItems || [];

    const dataList = useMemo(
        () =>
            AlbumSideBarData.map((item) => {
                return {
                    name: item.categoriesTitle?.value || '',
                    key: item.name,
                };
            }),
        [AlbumSideBarData],
    );

    const albumItemData = useMemo(
        () => AlbumSideBarData.find((i) => i.name === page),
        [AlbumSideBarData, page],
    );

    const onMediaSelect = (option: { label: JSX.Element; value: any; key: any }) => {
        if (pathname.includes(option.key)) return;
        router.replace(pathname.replace(page, option.key));
    };

    return (
        <>
            <Row gutter={[20, 20]}>
                {isMobile && (
                    <Flex justify='end' className='w-full pr-[12px]'>
                        <MediaDropdown
                            className='min-w-[280px]'
                            dataList={dataList}
                            value={t('selectCategories')}
                            onClick={onMediaSelect}
                        />
                    </Flex>
                )}
                <Col span={isMobile ? 24 : 16} className=''>
                    <AlbumPart albumItemData={albumItemData} />
                </Col>
                {!isMobile && (
                    <Col span={8} className=''>
                        <AlbumSideBar id={page} AlbumSideBarData={AlbumSideBarData} />
                    </Col>
                )}
            </Row>
        </>
    );
};

export default AlbumPageLayout;
