'use client';
import { Col, Row, Image, Flex } from 'antd';
import cn from 'classnames';
import {
    LeftImageRightContentFragment,
    LeftContentRightImageFragment,
} from '@/@types/generated/sitecore';
import { mobileBreakPoint, withFullPath } from '@/utils/utils';
import useIsMobile from '@/hooks/useIsMobile';

type Props = {
    content: any;
};

// const pagesMobileSize = [
//     {
//         page: 'moment-of-the-year',
//         mobileSize: 601,
//     },
// ];

const ImageContent = (props: Props) => {
    // console.log('ImageContent------', props);
    const { windowSize } = useIsMobile();

    const leftImageRightContent =
        'rightContent' in props.content
            ? (props.content as LeftImageRightContentFragment & {
                  hideLeftImgInMobile?: boolean;
                  mobileSize?: number;
              })
            : undefined;

    const leftContentRightImage =
        'leftContent' in props.content
            ? (props.content as LeftContentRightImageFragment)
            : undefined;
    // console.log('leftImageRightContent------', leftImageRightContent);
    // console.log('leftContentRightImage------', leftContentRightImage);

    const { leftImage, leftCaption, rightContent, mobileSize, hideLeftImgInMobile } =
        leftImageRightContent || {};

    const { leftContent, rightImage, rightCaption } = leftContentRightImage || {};

    // const currentPage = pagesMobileSize.find((i) => (props.className || '').includes(i.page || ''));

    const isMobile = windowSize.width < (mobileSize || mobileBreakPoint);

    const leftWidth = leftContentRightImage?.leftWidth || leftImageRightContent?.leftWidth;
    const rightWidth = leftContentRightImage?.rightWidth || leftImageRightContent?.rightWidth;
    const richTextContent = leftContent?.rendered || rightContent?.rendered || '';
    const imageUrl = leftImage?.src || rightImage?.src || '';
    const caption = leftCaption?.value || rightCaption?.value || '';

    const leftWidthValue = leftWidth?.value ? `${leftWidth?.value}%` : 'auto';
    const rightWidthValue = rightWidth?.value ? `${rightWidth?.value}%` : 'auto';

    return (
        <Row gutter={[20, 20]}>
            <Col
                flex={isMobile ? '100%' : leftContentRightImage ? leftWidthValue : rightWidthValue}
                order={leftContentRightImage ? 1 : 2}
                // className='!px-[15px]'
            >
                <div
                    dangerouslySetInnerHTML={{
                        __html: richTextContent,
                    }}
                />
            </Col>
            <Col
                flex={isMobile ? '100%' : leftImageRightContent ? leftWidthValue : rightWidthValue}
                order={leftImageRightContent ? 1 : 2}
                // className='!px-[27px]'
            >
                <Flex vertical gap={10}>
                    {imageUrl && (
                        <>
                            <Image
                                src={withFullPath(imageUrl)}
                                alt=''
                                preview={false}
                                rootClassName={cn(hideLeftImgInMobile && isMobile && '!hidden')}
                            />
                            <p className='text-sm leading-5'>{caption}</p>
                        </>
                    )}
                </Flex>
            </Col>
        </Row>
    );
};

export default ImageContent;
