'use client';
import { VideoFolderFragment } from '@/@types/generated/sitecore';
import cn from 'classnames';
import { useRouter } from 'next/navigation';
import { useGlobalStore } from '@/hooks/useGlobalStore';
import { useTranslation } from '@/i18n/use-transition.client';

type Props = {
    id: string;
    VideoSideBarData: VideoFolderFragment[];
};

const VideoSideBar = (props: Props) => {
    const router = useRouter();
    const { t } = useTranslation();
    const globalStore = useGlobalStore();
    const lang = globalStore.language;
    const { id, VideoSideBarData } = props;

    return (
        <div>
            <h4>{t('VideoCategories')}</h4>
            {VideoSideBarData.map((item, index) => {
                const isActive = String(item.name) === id;
                return (
                    <div
                        key={index}
                        className={cn(
                            'cursor-pointer hover:underline py-[10px] pl-[5px] min-h-[50px] max-h-[150px] border-b border-gray-300',
                            isActive
                                ? 'bg-[#5170a9] text-white'
                                : 'text-[#0c2d69] hover:text-[#333]',
                        )}
                        onClick={() => {
                            router.push(`/${lang}/videos/${item.name}`);
                        }}
                    >
                        {item.categoriesTitle?.value ?? ''}
                    </div>
                );
            })}
        </div>
    );
};

export default VideoSideBar;
