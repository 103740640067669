'use client';

import { withBasePath, withFullPath } from '@/utils/utils';
import { Flex, Image } from 'antd';
import { RaceVideoBlockFragment } from '@/@types/generated/sitecore';
import Dialog from '../Dialog';
import { useEffect, useState } from 'react';
import cn from 'classnames';
import styles from './index.module.scss';
import VideoIframe from '../VideoIframe';
import useIsMobile from '@/hooks/useIsMobile';
import RichText from '../RichText';

interface Props {
    className?: string;
    lang?: string;
    content: { template: { name: string } } & RaceVideoBlockFragment;
}

const HomeVideo = (props: Props) => {
    const { isMobile } = useIsMobile();
    // console.log('HomeVideo----RaceVideoBlock--------', props);
    const { videoImage, videoImageBg, videoLink, videoTitle, buttonContent } = props.content;

    const [modalShow, setModalShow] = useState(false);
    const [thumbImg, setThumbImg] = useState('');
    const [videoSrc, setVideoSrc] = useState('');
    const [windowWidth, setWindowWidth] = useState<number>(0);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        handleResize();

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div
            style={{
                backgroundImage: `url(${withFullPath(videoImageBg?.src ?? '')})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center bottom',
            }}
            className='py-[50px]'
        >
            <Flex vertical gap={30} justify='center' align='center'>
                <div
                    className='cursor-pointer relative'
                    onClick={() => {
                        setModalShow(true);
                        setThumbImg(withFullPath(videoImage?.src ?? ''));
                        setVideoSrc(videoLink?.value ?? '');
                    }}
                >
                    <Image
                        preview={false}
                        className='cursor-pointer '
                        width={isMobile ? `${windowWidth - 30}px` : 490}
                        src={withFullPath(videoImage?.src ?? '')}
                        alt=''
                    />
                    <Flex
                        vertical
                        justify='center'
                        align='center'
                        gap={20}
                        className='absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]'
                    >
                        <Image
                            preview={false}
                            className='cursor-pointer'
                            width={50}
                            src={withBasePath('/images/videoPlay.png')}
                            alt=''
                        />
                        <div
                            className='bg-black/50 text-white text-center max-w-[400px] py-[3px]'
                            style={{ width: `${isMobile ? `${windowWidth - 40}px` : '400px'}` }}
                        >
                            {videoTitle?.value ?? ''}
                        </div>
                    </Flex>
                </div>

                <RichText
                    content={{ richTextContent: { rendered: buttonContent?.rendered ?? '' } }}
                />
                {/* <Flex vertical justify='center' align='center' gap={5}>
                    <Flex vertical={false} align='center'>
                        <Image
                            preview={false}
                            height={25}
                            src={withBasePath('/images/btn-video-arrow.png')}
                            alt=''
                        />
                        <div className='text-[#fecf13] hover:underline pl-[10px] font-bold cursor-pointer'>
                            Other Videos
                        </div>
                    </Flex>

                    <Flex vertical={false} align='center'>
                        <Image
                            preview={false}
                            height={25}
                            src={withBasePath('/images/download-arrow.png')}
                            alt=''
                        />
                        <div className='text-[#fecf13] hover:underline pl-[10px] font-bold cursor-pointer'>
                            CRC Aerial View
                        </div>
                    </Flex>
                </Flex> */}
            </Flex>

            <Dialog
                open={modalShow}
                destroyOnClose
                onCancel={() => {
                    setModalShow(false);
                }}
                // className={cn('!w-fit', styles.dialogWrap)}
                width={isMobile ? '100%' : 800}
                styles={{
                    content: {
                        paddingTop: '60px',
                    },
                }}
                closeIcon={
                    <div
                        style={{ display: 'flex', justifyContent: 'flex-end' }}
                        onClick={() => {
                            setModalShow(false);
                        }}
                    >
                        <Image
                            width={50}
                            height={50}
                            alt=''
                            src={withBasePath('/images/cross.png')}
                            preview={false}
                        />
                    </div>
                }
            >
                <VideoIframe
                    // className={cn('w-[800px]')}
                    className={cn('w-full')}
                    videoPoster={thumbImg}
                    videoLink={videoSrc}
                    videoAutoPlay
                />
            </Dialog>
        </div>
    );
};

export default HomeVideo;
