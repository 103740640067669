'use client';
import { useState } from 'react';
import { Col, Flex, Image, Row } from 'antd';
import cn from 'classnames';
import Dialog from '@components/Dialog';
import style from '../index.module.scss';
import VideoIframe from '@/components/VideoIframe';
import { VideoFolderFragment } from '@/@types/generated/sitecore';
import { withBasePath, withFullPath } from '@/utils/utils';
import useIsMobile from '@/hooks/useIsMobile';

type Props = {
    videoItemData?: VideoFolderFragment;
};

const VideoPart = (props: Props) => {
    // console.log('props-----', props);
    const { categoriesTitle, videoFolderList = [], layoutType } = props.videoItemData || {};

    const [isHovered, setIsHovered] = useState('');

    const videoSubList = videoFolderList?.slice(1) || [];
    const isDefaultLayout = layoutType?.value === 'default';
    const { isMobile, windowSize } = useIsMobile();

    const [modalShow, setModalShow] = useState(false);
    const [thumbImg, setThumbImg] = useState('');
    const [videoSrc, setVideoSrc] = useState('');

    const handleMouseEnter = (i: string) => {
        // console.log('i---------', i);
        setIsHovered(i);
    };

    const handleMouseLeave = (i: string) => {
        setIsHovered(i);
    };

    return (
        <Flex vertical gap={10}>
            <div className='text-[#0c2d69] text-[1.125em] font-semibold mt-[10px]'>
                {categoriesTitle?.value ?? ''}
            </div>
            {isDefaultLayout ? (
                <>
                    <div
                        className={`${style.imageWrap} relative`}
                        onClick={() => {
                            setModalShow(true);
                            setThumbImg(
                                withFullPath(videoFolderList[0]?.videoThumbnail?.src ?? ''),
                            );
                            setVideoSrc(videoFolderList[0]?.videoSource?.value ?? '');
                        }}
                        onMouseEnter={() =>
                            handleMouseEnter(videoFolderList[0]?.videoThumbnail?.src ?? '')
                        }
                        onMouseLeave={() => handleMouseEnter('')}
                    >
                        <Image
                            className='cursor-pointer'
                            width={'100%'}
                            alt=''
                            src={withFullPath(videoFolderList[0]?.videoThumbnail?.src ?? '')}
                            preview={false}
                        />
                        <div className='absolute bottom-1 right-2 cursor-pointer'>
                            <Image
                                width={50}
                                height={50}
                                alt=''
                                src={withBasePath(
                                    `/images/video-play-${isHovered === videoFolderList[0]?.videoThumbnail?.src ? 'on' : 'off'}.png`,
                                )}
                                preview={false}
                            />
                        </div>
                    </div>
                    <p className='text-[#0c2d69] text-[0.875em] leading-[1.3125em]'>
                        {videoFolderList[0]?.videoTitle?.value ?? ''}
                    </p>
                    <hr className='w-full border-t border-[#c0ddf5] mb-[18px]' />

                    {videoSubList.length > 0 && (
                        <Row gutter={[10, 25]}>
                            {videoSubList.map((item, index) => {
                                return (
                                    <Col span={windowSize.width < 768 ? 24 : 8} key={index}>
                                        <div
                                            className={`${style.imageWrap} relative`}
                                            onClick={() => {
                                                setModalShow(true);
                                                setThumbImg(
                                                    withFullPath(item.videoThumbnail?.src ?? ''),
                                                );
                                                setVideoSrc(item.videoSource?.value ?? '');
                                            }}
                                            onMouseEnter={() =>
                                                handleMouseEnter(item.videoThumbnail?.src || '')
                                            }
                                            onMouseLeave={() => handleMouseLeave('')}
                                        >
                                            <Image
                                                className='cursor-pointer'
                                                width={'100%'}
                                                // height={152}
                                                height={'auto'}
                                                alt=''
                                                src={withFullPath(item.videoThumbnail?.src ?? '')}
                                                preview={false}
                                            />
                                            <div className='absolute bottom-1 right-2 cursor-pointer'>
                                                <Image
                                                    width={50}
                                                    height={50}
                                                    alt=''
                                                    src={withBasePath(
                                                        `/images/video-play-${isHovered === item.videoThumbnail?.src ? 'on' : 'off'}.png`,
                                                    )}
                                                    preview={false}
                                                />
                                            </div>

                                            {/* <div className={style.playIcon} /> */}
                                        </div>
                                        <p className='text-[#0c2d69] text-[0.875em] leading-[1.3125em]'>
                                            {item.videoTitle?.value ?? ''}
                                        </p>
                                    </Col>
                                );
                            })}
                        </Row>
                    )}
                </>
            ) : (
                <>
                    {videoFolderList?.length > 0 && (
                        <Row gutter={[10, 25]}>
                            {videoFolderList?.map((item, index) => {
                                return (
                                    <Col span={windowSize.width < 768 ? 24 : 8} key={index}>
                                        <div
                                            className={style.imageWrap}
                                            onClick={() => {
                                                setModalShow(true);
                                                setThumbImg(
                                                    withFullPath(item.videoThumbnail?.src ?? ''),
                                                );
                                                setVideoSrc(item.videoSource?.value ?? '');
                                            }}
                                            onMouseEnter={() =>
                                                handleMouseEnter(item.videoThumbnail?.src || '')
                                            }
                                            onMouseLeave={() => handleMouseLeave('')}
                                        >
                                            <Image
                                                className='cursor-pointer'
                                                width={'100%'}
                                                // height={152}
                                                height={'auto'}
                                                alt=''
                                                src={withFullPath(item.videoThumbnail?.src ?? '')}
                                                preview={false}
                                            />

                                            <div className='absolute bottom-1 right-2 cursor-pointer'>
                                                <Image
                                                    width={50}
                                                    height={50}
                                                    alt=''
                                                    src={withBasePath(
                                                        `/images/video-play-${isHovered === item.videoThumbnail?.src ? 'on' : 'off'}.png`,
                                                    )}
                                                    preview={false}
                                                />
                                            </div>
                                            {/* <div className={style.playIcon} /> */}
                                        </div>
                                        <p className='text-[#0c2d69] text-[0.875em] leading-[1.3125em]'>
                                            {item.videoTitle?.value ?? ''}
                                        </p>
                                    </Col>
                                );
                            })}
                        </Row>
                    )}
                </>
            )}

            <Dialog
                open={modalShow}
                destroyOnClose
                onCancel={() => {
                    setModalShow(false);
                }}
                // className={cn('!w-fit', style.dialogWrap)}
                styles={{
                    content: {
                        padding: '0',
                    },
                }}
                width={isMobile ? '100%' : 800}
                closeIcon={false}
            >
                <div
                    style={{ display: 'flex', justifyContent: 'flex-end' }}
                    onClick={() => {
                        setModalShow(false);
                    }}
                >
                    <Image
                        width={50}
                        height={50}
                        alt=''
                        src={withBasePath('/images/cross.png')}
                        preview={false}
                    />
                </div>

                <VideoIframe
                    className={cn('w-full')}
                    videoPoster={thumbImg}
                    videoLink={videoSrc}
                    videoAutoPlay
                />
            </Dialog>
        </Flex>
    );
};

export default VideoPart;
